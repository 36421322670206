exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-bank-js": () => import("./../../../src/pages/work/bank.js" /* webpackChunkName: "component---src-pages-work-bank-js" */),
  "component---src-pages-work-bonfire-js": () => import("./../../../src/pages/work/bonfire.js" /* webpackChunkName: "component---src-pages-work-bonfire-js" */),
  "component---src-pages-work-car-js": () => import("./../../../src/pages/work/car.js" /* webpackChunkName: "component---src-pages-work-car-js" */),
  "component---src-pages-work-cubits-js": () => import("./../../../src/pages/work/cubits.js" /* webpackChunkName: "component---src-pages-work-cubits-js" */),
  "component---src-pages-work-df-js": () => import("./../../../src/pages/work/df.js" /* webpackChunkName: "component---src-pages-work-df-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-magicmoney-js": () => import("./../../../src/pages/work/magicmoney.js" /* webpackChunkName: "component---src-pages-work-magicmoney-js" */),
  "component---src-pages-work-p-2-p-js": () => import("./../../../src/pages/work/p2p.js" /* webpackChunkName: "component---src-pages-work-p-2-p-js" */),
  "component---src-pages-work-stake-js": () => import("./../../../src/pages/work/stake.js" /* webpackChunkName: "component---src-pages-work-stake-js" */)
}

